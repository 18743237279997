@import './consts.scss';

// Global styles

.bold {
  font-weight: bold;
  color: #697987;
}

.italic {
  font-style: italic;
}

h2,
h3 {
  font-size: 25px;
  color: $color-primary;
}
h3 {
  font-size: 16px;
}

.button-row {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
}

.error {
  color: $color-error;
}

// Pages

.outter-container {
  display: flex;
  justify-content: center;
}

.container {
  max-width: $max-width;
}

.center {
  display: grid;
  place-items: center;
}

.mat-stroked-button,
.mat-button {
  border-radius: 0;
}

davinci-text-area {
  font-size: 1rem;
}
