.row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;

  margin-bottom: 1rem;
  min-height: 58px;

  align-items: center;

  app-unit-field,
  davinci-button,
  davinci-text-field,
  davinci-drop-down {
    width: 100%;
    margin: 0;
  }

  &.no-margin-bottom {
    margin-bottom: 0rem;
  }
}
.col-1 {
  grid-column: 1 / 2;
}
.col-2 {
  grid-column: 2 / 3;
}
.col-3 {
  grid-column: 3 / 4;
}
.col-4 {
  grid-column: 4 / 5;
}
.col-1-to-2 {
  grid-column: 1 / 3;
}
.col-1-to-3 {
  grid-column: 1 / 4;
}
.col-1-to-4 {
  grid-column: 1 / 5;
}
.col-2 {
  grid-column: 2;
}
.col-2-to-3 {
  grid-column: 2 / 4;
}
.col-2-to-4 {
  grid-column: 2 / 5;
}
.col-3-to-4 {
  grid-column: 3 / 5;
}
